.contact {
    display: flex;
    justify-content: center;
}

.contact__title__name {
    display: flex;
    flex-direction: column;
}


input {
    margin: 1rem 0;
    outline: none;
    width: 25vw;
    height: 3rem;
    padding: 0 1rem;
    border: none;
    border-radius: 1rem;
    background-color: var(--color-7);
    color: var(--color-6);
    box-shadow: var(--box-shadow-big);
}

textarea {
    margin: 1rem 0;
    outline: none;
    width: 25vw;
    height: 10rem;
    padding: 1rem 1rem;
    border: none;
    border-radius: 1rem;
    color: var(--color-6);
    background-color: var(--color-7);
    box-shadow: var(--box-shadow-big);
}

label {
    font-weight: bold;
}


::-webkit-input-placeholder { /* WebKit browsers */
    color: var(--color-6) !important;
}

.contact__title__email {
    display: flex;
    flex-direction: column;
}

.contact__title__message {
    display: flex;
    flex-direction: column;
}

.contact__title__button{
    margin-top: 1rem;
    margin-bottom: 5rem;
    background-color: var(--color-7);
    color: var(--color-6);
    font-size: 1rem;
    font-weight: bold;
    width: 15rem;
    outline: none;
    border: none;
    border-radius: 1rem;
    padding: 1rem;
    box-shadow: var(--box-shadow-big);
}

@media only screen and (max-width: 1024px) {
    input {
        width: 120%;
    }
    textarea{
        width: 120%;
    }
    .contact__title__button{
        width: 75%;
    }
    .contact {
        margin-right: 4rem;
        justify-content: none;

    }
}

@media only screen and (max-width: 620px) {
    input {
        width: 120%;

    }
    textarea {
        width: 120%;
    }
    .contact__title__button{
        width: 75%;
    }
    .contact {
        margin-right: 4rem;
        justify-content: none;

    }
}