.projects__heading {
    display: flex;
    justify-content: center;
    /* background-color: pink; */
    /* text-decoration: underline; */
    /* background-color: black; */
    color: var(--color-7);
}


.projects {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5rem;
    margin: 2rem 10rem;
    color: var(--color-11);


}

.projects__container {
    display: grid;
    grid-template-rows: 3fr fr 2fr 1fr;
    grid-gap: 2rem;
    border-radius: 1rem;
    margin-bottom: 5rem;
    max-width: 375px;
}

.projects__container__pic {
    box-shadow: var(--box-shadow-big);
    border-radius: 1rem;
    background-color: var(--color-7);
    height: 16rem;
    width: 100%;
    object-fit: cover;
}

.projects__container__pic video {
    border-radius: 1rem;
    background-color: var(--color-7);
    margin-top: 1.8rem;
    width: 100%;
    object-fit: cover;
    outline: none;
}

.projects__container__name {
    box-shadow: var(--box-shadow-big);
    border-radius: 1rem;
    background-color: var(--color-7);
    margin: auto 0;
}

.projects__container__name h2 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.projects__container__info {
    box-shadow: var(--box-shadow-big);
    border-radius: 1rem;
    background-color: var(--color-7);
}

.projects__container__info {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}
.projects__container__links {
    display: flex;
    border-radius: 1rem;
    justify-content: space-between;

}

.projects__container__links p {
    cursor: pointer;
    font-weight: bold;
    padding: 1rem;
    border-radius: 1rem;
    background-color: var(--color-7);
    color: var(--color-11);
    box-shadow: var(--box-shadow-big);

    /* box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
}

.projects__container__links p:hover {
    color: var(--color-7);
    background-color: var(--color-11);
}

@media only screen and (max-width: 1024px) {
    .projects {
        grid-template-columns: 1fr 1fr;
        max-width: 900px;
        /* justify-content: center; */
        margin: 4rem 1rem 2rem 1rem;
        grid-row-gap: 2rem;
        grid-column-gap: 2rem;


    }

    /* .profile__container3 {
        display: none;
    } */
}

@media only screen and (max-width: 620px) {
    .projects {
        grid-template-columns: 1fr;
        max-width: 900px;
        /* justify-content: center; */
        margin: 4rem 1rem 2rem 1rem;
        grid-row-gap: 2rem;

    }
    
.projects__container {
    margin-bottom: 2rem;
}

    /* .projects__container3 {
        display: none;
    } */
}

