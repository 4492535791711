.fullpage {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: center;
}

.profile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 17rem;
    max-width: 1000px;
    margin: 4rem 10rem 2rem 10rem;
    color: var(--color-6);
    color: var(--color-11);
    min-height: 35rem;
}

.profile__container1 {
    display: grid;
    grid-template-rows: 3fr 1.2fr 1fr;
    grid-gap: 2rem;
    border-radius: 1rem;
    max-width: 30rem; 
}

.profile__container1__pic {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
        box-shadow: var(--box-shadow-big);
    /* box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
    background-color: var(--color-7);
    min-height: 15rem;

}

.profile__container1__pic img {
    border-radius: 50%;
    /* box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
    height: 10rem;
    /* box-shadow: rgba(19, 12, 12, 0.56) 0px 22px 70px 4px; */

}


.profile__container1__name {
    border-radius: 0.5rem;
    /* box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
    background-color: var(--color-7);
    box-shadow: var(--box-shadow-big);


}

.profile__container1__name h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.2rem;
}

.profile__container1__name p {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
}

.profile__container1__copy:hover {
    color: var(--color-6);

}

.profile__container1__email {
    display: flex;
    justify-content: center;
    cursor: pointer;
}
.profile__container1__email p {
    margin-right: 1rem;
    margin-bottom: 0.4rem;
}

.profile__container1__cv {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1.5rem;
    /* justify-content: center; */
    font-weight: bold;
    color: var(--color-7);
    background-color: var(--color-11);
    border-radius: 1rem;
    width: 19rem;
    margin: 0 auto 0.3rem;
    border: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;


}

.profile__container1__cv:hover {
    background-color: var(--color-11);
    /* color: var(--color-11); */

}

.profile__container1__cv p {
    margin-bottom: 0;


}

.profile__container1__down{
    padding-top: 0.2rem;
}
.profile__container1__link {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.profile__container1__link__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    background-color: var(--color-7);
    border-radius: 2rem;
    padding: 1rem;
    filter: brightness(95%);
    transform: translate(-90%, -120%);
    text-decoration: none;
    color: var(--color-11);
    box-shadow: var(--box-shadow-small);
}

.profile__container1__link__cancel {
    display: flex;
    justify-content: space-between;
}

.profile__container1__link__clip {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.profile__container1__link__clip p {
    margin-right: 0.4rem;
    
}

.profile__container1__link__msg {
    color: var(--color-6);
}

.profile__container1__link a {
    margin-bottom: 0;
    border-radius: 50%;
}

.profile__container1__icon {
    font-size: 2rem;
    color: var(--color-11);
    transition: all .3s ease-out;
    padding: 0.8rem;
    background-color: var(--color-7);
    border-radius: 1rem;
    /* box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
    /* box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px; */
    /* box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px; */
    box-shadow: var(--box-shadow-big);
}

.profile__container1__icon:hover {
    /* transform: translate(0, -5px); */
    transform: scale(0.9);

    color: var(--color-7);
    background-color: var(--color-11);
}

.profile__container1__icon__mail {
    cursor: pointer;
}

.profile__container2 {
    display: grid;
    grid-template-rows: 1.5fr 1fr;
    grid-gap: 3rem;    
    border-radius: 1rem;
    max-width: 30rem;

}

.profile__container2__bio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    box-shadow: var(--box-shadow-big);
    background-color: var(--color-7);

}

.profile__container2__bio h3 {
    padding: 1rem 1rem 1rem 0.5rem;
    margin-bottom: 0;
}

.profile__container2__bio p {
    padding: 1rem;
    margin-top: 0;
}

.profile__container2__skills {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: var(--box-shadow-big);
    border-radius: 1rem;
    background-color: var(--color-7);


}

.profile__container2__skills h3 {
    margin-bottom: 0;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--color-11)
}

.profile__container2__skills__icons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 0;
    color: var(--color-11)
}

.profile__container2__skills__icons div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile__container3 {
    display: grid;
    grid-template-rows: 2fr 1fr 0.5fr;
    grid-gap: 2rem;  
    border-radius: 1rem;
}

.profile__container3__pic {
    box-shadow: var(--box-shadow-big);
    border-radius: 1rem;
    width: 100%;
    height: 18rem;
    object-fit: cover;
    filter: brightness(80%)
}



.profile__container3__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: var(--box-shadow-big);
    border-radius: 1rem;
    background-color: var(--color-7);
    
}

.profile__container3__info h3 {
    display: flex;
    align-items: center;
    justify-content: center;
}
.profile__container3__info p {
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile__container3 button {
    width: 100%;
    border-radius: 1rem;
    border: none;
    outline: none;
    box-shadow: var(--box-shadow-big);

    /* box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
    background-color: var(--color-7);
    color: var(--color-11);
    font-size: 1rem;
    font-weight: bold;
}

button:hover {
    color: var(--color-7);
    background-color: var(--color-11);
}
.profile__downarrowdiv {
    display: flex;
    justify-content: center;
}
.profile__downarrow {
    font-size: 2rem;
    color: var(--color-7);
    cursor: pointer;
    /* align-items: center; */
    /* background-color: pink; */
    /* -webkit-animation: bounce .5s infinite alternate;
    -moz-animation: bounce .5s infinite alternate;
    animation: bounce .5s infinite alternate; */
    animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
}

.profile__downarrow:hover {
    /* color: var(--color-11); */
    animation: none;
    -webkit-animation: none;
    animation: none;
}

@media only screen and (max-width: 1024px) {
    .profile {
        grid-template-columns: 1fr 1fr;
        max-width: 900px;

        /* justify-content: center; */
        margin: 4rem 1rem 2rem 1rem;
        grid-row-gap: 1rem;
        /* grid-columns-gap: 2rem ; */
        grid-column-gap: 2rem;
    }

    .profile__container2__skills {
        min-height: 12rem;
    }

    .profile__container3 {
        display: none;
    }
}

@media only screen and (max-width: 620px) {
    .profile {
        grid-template-columns: 1fr;
        max-width: 900px;
        /* justify-content: center; */
        margin: 4rem 1rem 2rem 1rem;
        grid-row-gap: 2rem;
    }

    .profile__container3 {
        display: none;
    }
}

