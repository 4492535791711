.navbar {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  .menu__bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
    position: fixed;
    margin-top: 10rem;
    z-index: 1;
    cursor: pointer;

  }

  .menu__bars__icon {
    /* color: var(--color-8) !important; */
    transition: transform 0.2s ease-in;
    box-shadow: var(--box-shadow-big);
    background-color: var(--color-6);
    border-radius: 1rem;
    padding: 0.5rem;
    /* background-color: pink; */
  }

  .menu__bars__icon:hover {
    transform: scale(0.9);
    color: var(--color-6) !important;
    background-color: var(--color-7);
    border: 2px solid var(--color-6);
  }
  
  .nav__menu {
    background-color: var(--color-7);
    box-shadow: var(--box-shadow-small);
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    border-radius: 2rem;
  }
  
  .nav__menu.active {
      display: flex;
      align-items: center;
      left: 0;
      transition: 350ms;
      margin-left: -20px;
      padding: 0;
  }
  
  .nav__text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px;
    list-style: none;
    height: 60px;
  }
  
  .nav__text a {
    text-decoration: none;
    color: var(--color-6);
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    font-weight: bold;
    color: var(--color-11);

  }
  
  .nav__text a:hover {
    background-color: var(--color-11);
    color: var(--color-7);
    border-radius: 1rem;
    cursor: pointer;
  }
  
  .nav__menu__items {
    width: 100%;
  }
  
  .navbar__toggle {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  span {
    margin-left: 16px;
  }

  @media only screen and (max-width: 1024px) {
    .menu__bars__icon:hover {
      transform: none;
      background-color: var(--color-6);
      color: var(--color-7) !important;


    }

}

@media only screen and (max-width: 768px) {
  
}

