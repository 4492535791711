body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-6);
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  cursor: pointer;
}

button:focus {
  outline: none;
}

:root {
  --color-1: #1f1f1f;
  --color-2: white;
  --color-3: #C4C4C4;
  --color-4: #3e9fd7;
  --color-5: #4851d2;
  --color-6: #EBEBEB;
  --color-7: black;
  --color-8: #E50914;
  --color-9: #162447;
  --color-10: #056674;
  --color-11: #EBEBEB;
  --bg-color-1: #30b78d;
  --bg-color-2: #ea5e6b;
  --bg-color-3: #f2b51e;
  --bg-color-4: #181818;
  --bg-color-5: #EBEBEB;
  /* --box-shadow-big: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px; */
  --box-shadow-big: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  --box-shadow-small: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  /* --box-shadow-3: #E50914  0px 3px 6px 3px; */


}


a {
  text-decoration: none;
  color: var(--color-6);
  transition: all .3s ease-out;
}

a:hover {
  /* transform: translate(0, -5px); */
  transform: scale(0.9);
}

button {
  transition: all .3s ease-out;
}

button:hover {
  /* transform: translate(0, -5px); */
  transform: scale(0.9);

}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);  */
  background-color: var(--color-7);
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

}

@media only screen and (max-width: 1024px) {
  a:hover {
    transform: none;
  }
  button:hover {
    transform: none;
  }

}

